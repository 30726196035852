import {Component, Inject, OnInit} from '@angular/core';
import {ConfirmModalComponent} from '../../confirm-modal/confirm-modal.component';
import {ErrorDialogComponent} from '../../general/error-dialog/error-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatSnackBar} from '@angular/material';
import {CreateEditModalComponent} from '../create-edit-modal/create-edit-modal.component';
import {PhoneListService} from '../../../services/phone-list/phone-list.service';
import BaseComponent from '../../base/base.component';
import {Permissions} from '../../../helpers/Permissions';

@Component({
  selector: 'app-phone-list',
  templateUrl: './phone-list.component.html',
  styleUrls: ['./phone-list.component.less']
})
export class PhoneListComponent extends BaseComponent implements OnInit {


  public open_phone_list = false;

  public lists: any = {
    data: [],
    page_size: 10,
    page: 0,
    rows: 0,
  };

  public loader = true;

  public lazyId = {};

  public toolbarMenu = [
    {
      id: 'edit',
      label: 'Edit List',
      icon: 'edit',
      show: this.permissions.can('phone_list', 'can_update_phone_list'),
      fn: (list) => {
        const dialog = this.dialog.open(CreateEditModalComponent, {
          width: '600px',
          data: {
            list: list,
          }
        });

        dialog.afterClosed().subscribe((response) => {
          if (response) {
            this.toggleLoader();
            this.lists.page = 0;
            this.getLists();
          }
        });

      }
    },
    {
      id: 'clear',
      label: 'Clear list',
      icon: 'clear',
      show: this.permissions.can('phone_list', 'can_clear_phone_list'),
      fn: (list) => {
        const dialog = this.dialog.open(ConfirmModalComponent, {
          width: '450px',
          data: {
            title: list.name,
            description: 'Are you sure you want to delete all leads from \'' + list.name + '\'?'
          }
        });
        dialog.afterClosed().subscribe((res) => {
          if (res) {
            this.lazyId[list.id] = 'clear';
            this.phoneList.clearList(list.id).subscribe((resp) => {
              if (!resp.success) {
                this.dialog.open(ErrorDialogComponent, {
                  data: {
                    title: 'List can not be deleted',
                    message: resp.msg
                  }
                });
              } else {
                this.snackBar.open('List \'' + list.name + '\' was cleared!', 'Dismiss', {
                  duration: 3000,
                });
              }
              this.lazyId[list.id] = false;
            }, () => {
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  title: 'List can not be cleared',
                  message: 'Please contact your Administrator!'
                }
              });
              this.lazyId[list.id] = false;
            });
          }
        });
      }
    },
    {
      id: 'delete',
      label: 'Delete List',
      icon: 'delete',
      show: this.permissions.can('phone_list', 'can_remove_phone_list'),
      fn: (list) => {
        const dialog = this.dialog.open(ConfirmModalComponent, {
          width: '450px',
          data: {
            title: list.name,
            description: ' Are you sure want delete \'' + list.name + '\' list?'
          }
        });
        dialog.afterClosed().subscribe((result) => {
          if (result) {
            this.toggleLoader();
            this.phoneList.deletePhoneList(list.id).subscribe(() => {
              this.snackBar.open('List \'' + list.name + '\' was deleted', 'Dismiss', {
                duration: 3000,
              });
              this.lists.page = 0;
              this.getLists();
            }, () => {
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  title: 'List can not be deleted',
                  message: 'Please contact your Administrator!'
                }
              });
              this.toggleLoader();
            });
          }
        });
      }
    }
  ];

  constructor(private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private phoneList: PhoneListService,
              private snackBar: MatSnackBar,
              public permissions: Permissions) {
    super();
  }

  ngOnInit() {
    this.getLists();
  }

  public changePage(event: any): void {
    this.toggleLoader();
    this.lists.page = event.pageIndex;
    this.lists.page_size = event.pageSize;
    this.getLists();
  }

  public selectOptionsList(option: any, list: any): void {
    option.fn(list);
  }

  public addNewList() {
    const dialog = this.dialog.open(CreateEditModalComponent, {
      width: '600px',
      data: {
        queue_id: this.data.id
      }
    });
    dialog.afterClosed().subscribe((response) => {
      if (response) {
        this.toggleLoader();
        this.lists.page = 0;
        this.getLists();
      }
    });
  }


  public getLists() {
    this.phoneList.getPhoneLists(this.data.id, {
      page: this.lists.page,
      page_size: this.lists.page_size,
      ['sort[id]']: 'desc'
    }).subscribe((response) => {
      this.lists = Object.assign(this.lists, response);

      this.open_phone_list = this.lists.rows <= 0;

      this.toggleLoader();
    });
  }

}
