import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import BaseComponent from '../base/base.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../services/user/user.service';
import {Cookies} from '../../helpers/Cookies';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as LoginAction from '../../actions/login.actions';
import {PermissionsService} from '../../services/permissions/permissions.service';
import {ROLE_TYPES, TYPE_ADMIN} from '../../constants/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})

export class LoginComponent extends BaseComponent implements OnInit {

  @ViewChild('authCodeInput') authCodeInput!: ElementRef<HTMLInputElement>;

  public loader: boolean;

  public login: FormGroup;

  constructor(
      private userService: UserService,
      private router: Router,
      private store: Store<any>,
      private permissionsService: PermissionsService
  ) {
    super();
  }

  ngOnInit() {
    this.login = new FormGroup({
      username: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ])
    });
  }

  // private getPermissions(id, callback?): void {
  //   if (id === '@') {
  //     localStorage.setItem('is_admin', 'true');
  //     if (callback) {
  //       callback();
  //     }
  //   } else {
  //     this.subscribers.getGroups = this.permissionsService.getPermissions(id).subscribe(res => {
  //       if (res) {
  //         localStorage.setItem('as-permissions', JSON.stringify(Object.values(res)));
  //         localStorage.removeItem('is_admin');
  //       }
  //
  //       if (callback) {
  //         callback();
  //       }
  //     });
  //   }
  // }

  public logIn(event) {
    event.preventDefault();

    if (!this.login.valid) {
      return;
    }

    this.loader = true;

    this.userService.login({
      body: this.login.value
    }).subscribe((response) => {

      if(response && response.fa2){
        this.login.addControl('code', new FormControl('', []));
        setTimeout(() => {
          this.login.get('code').setValidators([Validators.required, Validators.maxLength(6), Validators.minLength(6), Validators.pattern('^[0-9]*$')]);
          if (this.authCodeInput) {
            this.authCodeInput.nativeElement.focus();
          }
        })
        this.loader = false;
      } else {
        this.store.dispatch(new LoginAction.SetLogin(response));
        localStorage.setItem('user', JSON.stringify(response));
  
        Cookies.set('token', response.token, 30);
  
        if (response.new_crm_tokens && response.new_crm_tokens.Token) {
          Cookies.set('mario_token', response.new_crm_tokens.Token, 30);
        } else {
          console.warn('Need recheck backend you\'ve got problem with getting access tokens from New CRM!');
        }
  
        if (response.event_token) {
          Cookies.set('centrifugo', response.event_token, 30);
        } else {
          console.warn('Need recheck backend you\'ve got problem with getting access Centrifugo tokens from New CRM!');
        }
  
        // if (response.permissions) {
        //   localStorage.setItem('permissions', JSON.stringify(response.permissions));
        // } else {
        //   localStorage.setItem('permissions', '');
        // }
  
        if (response.ai_manager) {
          localStorage.setItem('ai_manager', '1');
        } else {
          localStorage.setItem('ai_manager', '0');
        }
  
        if (response.manage_view) {
          localStorage.setItem('manage_view', '1');
        } else {
          localStorage.setItem('manage_view', '0');
        }
  
        for (const name of Object.keys(localStorage)) {
          if (localStorage.hasOwnProperty(name) && /^width_view_/.test(name)) {
            localStorage.removeItem(name);
          }
        }
  
        if (response.view_settings) {
          for (const name in response.view_settings) {
            if (response.view_settings.hasOwnProperty(name)) {
              localStorage.setItem(name, JSON.stringify(response.view_settings[name]));
            }
          }
        }
  
        if (response.role_id === '@' || response.is_super_admin) {
          localStorage.setItem('is_admin', 'true');
        } else {
          localStorage.removeItem('is_admin');
        }
  
        // this.getPermissions(response.role_id, () => {
          document.dispatchEvent(new Event('login'));
  
          this.router.navigate(['/board/select']).then((res) => {
            if (res) {
              this.loader = false;
            } else {
              location.href = '/board/select';
            }
          });
      }
      // });
    }, ({error}) => {
      this.handleErrors(error);
      this.loader = false;
    });
  }

  public onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.replace(/[^0-9]/g, '');
    this.login.get('code').setValue(inputElement.value);
  }

  private handleErrors(errors): void {
    const general = [];

    for (const name in errors) {
      if (this.login.get(name)) {
        this.login.get(name).setErrors({name: errors[name]});
      } else {
        general.push(errors[name]);
      }
    }

    if (general.length) {
      this.login.setErrors({name: general.join(', ')});
    }
  }

}
