import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { AmChartsService } from '@amcharts/amcharts3-angular';
import { finalize, takeUntil } from 'rxjs/operators';
import { Unsubscribe } from '../../../classes/unsubscribe';
import { ViewService } from 'src/app/services/view/view.service';

@Component({
  selector: 'app-country-split-modal',
  templateUrl: './country-split-modal.component.html',
  styleUrls: ['./country-split-modal.component.less']
})
export class CountrySplitModalComponent extends Unsubscribe implements OnInit {

  public isLoading: boolean = false;

  public countrySplit = {
    'type': 'serial',
    'theme': 'none',
    'startDuration': 0,
    'dataProvider': [],
    'valueAxes': [{
      'title': 'Count',
      'gridColor': '#FFFFFF',
      'gridAlpha': 0,
      'dashLength': 0
    }],
    'gridAboveGraphs': true,
    'graphs': [{
      'balloonText': '[[category]]: <b>[[value]]</b>',
      'fillAlphas': 1,
      'lineAlpha': 0,
      'type': 'column',
      'fillColors':['#6794dc', '#67b7dc'],
      'valueField': 'value'
    }],
    'chartCursor': {
      'categoryBalloonEnabled': false,
      'cursorAlpha': 0,
      'zoomable': false
    },
    'categoryField': 'label',
    'categoryAxis': {
      'autoGridCount': false,
      'gridPosition': 'start',
      'gridAlpha': 0,
      'labelRotation': 90,
      'tickPosition': 'start',
      'tickLength': 10
    }
  };

  public chartRef = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public self: MatDialogRef<CountrySplitModalComponent>,
    private AmCharts: AmChartsService,
    private viewService: ViewService
  ) {
    super();
  }

  ngOnInit() {
    this.getData();
  }

  public getData(): void {
    this.isLoading = true;

    this.viewService.getViewCountryData(this.data.id, {}).pipe(
      takeUntil(this.destroy),
      finalize(() => setTimeout(() => this.isLoading = false, 150))
    ).subscribe({
      next: (res) => {
        this.buildCharts(res);
      }
    })
  }

  private buildCharts(data): void {
    this.countrySplit.dataProvider = Object.keys(data).map(key => ({
      label: data[key].country_of_residence,
      value: data[key].count
    }));

    this.countrySplit.categoryAxis['gridCount'] = this.countrySplit.dataProvider.length;

    this.countrySplit.dataProvider.sort((a, b) => b.value - a.value);

    if(this.chartRef){
      this.AmCharts.destroyChart(this.chartRef);
      this.chartRef = null;
    }

    setTimeout(() => {
      this.chartRef = this.AmCharts.makeChart('country-split', this.countrySplit);
    }, 100)
  }
}
