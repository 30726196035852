    import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';
import {SseService} from '../sse/sse.service';
import TokenGenerator from '../../helpers/TokenGenerator';
import store from 'store';

@Injectable({
    providedIn: 'root'
})

export class ViewService {

    constructor(
        private api: ApiService,
        private sse: SseService
    ) {
    }

    public getViews(data): Observable<any> {
        return this.api.get('v1/view', {
            params: data
        });
    }

    public getViewData(id, data): Observable<any> {
        localStorage.setItem('currentView', String(id));

        return this.api.get('v1/view/' + id + '/get-data', {
            params: Object.assign(data, {
                update_token: TokenGenerator.getToken()
            })
        });
    }

    public getViewCountryData(id, data): Observable<any> {
      return this.api.get('v1/view/' + id + '/get-data-country', {
          params: Object.assign(data, {
            update_token: TokenGenerator.getToken()
          })
      });
    }

    public getViewDataTab(params?: any): Observable<any> {
        return this.api.get('v1/view/views-tab', {
            params: params ? params : {},
        });
    }

    public getFreshLeadsInfo(data: any): Observable<any> {
        return this.api.get('v1/report/general', {
            body: data,
        });
    }

    public getSettings(params?): Observable<any> {
        return this.api.get('v1/view/settings', {params});
    }

    public createView(data): Observable<any> {
        return this.api.post('v1/view/create', {
            body: data
        });
    }

    public deleteView(id): Observable<any> {
        return this.api.delete('v1/view/' + id + '/delete', {});
    }

    public getView(id): Observable<any> {
        return this.api.get('v1/view/' + id + '/get-info', {});
    }

    public editVIew(id, data): Observable<any> {
        return this.api.post('v1/view/' + id + '/edit', {
            body: data
        });
    }

    public findViewClients(params): Observable<any> {
      return this.api.get('v1/view/find-client', {params});
    }

    public getUpdates(): Observable<any> {
        return this.sse.get('events/view');
    }

    public getAutoCompleteValues(fieldName: string) {
        return this.api.get('v1/view/auto-complete', {
            params: {
                field: fieldName
            }
        });

        // return new Observable((observer) => {
        //     const complete = store.get('autocomplete');
        //     const fieldNameArr = fieldName.split(',');
        //
        //     console.log('-----', fieldName, complete)
        //
        //     if (complete && fieldNameArr.every(i => complete[i])) {
        //         console.log('-----', 1111)
        //         observer.next(complete);
        //         observer.complete();
        //     } else {
        //         console.log('-----', 222)
        //         this.api.get('v1/view/auto-complete', {
        //             params: {
        //                 field: fieldName
        //             }
        //         }).subscribe(res => {
        //             store.set('autocomplete', res);
        //
        //             observer.next(res);
        //             observer.complete();
        //         });
        //     }
        // });
    }

    private checkAutoComplete(fieldName): boolean {
        const complete = store.get('autocomplete');
        const fieldNameArr = fieldName.split(',');
        if (fieldNameArr.every(i => !!complete[i])) {
            return true;
        }

        return false
    }

    public cloneView(id, params) {
        return this.api.post('v1/view/' + id + '/clone', {
            body: params
        });
    }

    public getDefaultView(params?) {
        return this.api.get('v1/view/get-default', {params});
    }

    public audience(body: any) {
        return this.api.post('v1/view/audience', {body});
    }
}
