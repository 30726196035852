import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {ComponentPortal, Portal} from '@angular/cdk/portal';
import BaseComponent from '../base/base.component';
import {Permissions} from 'src/app/helpers/Permissions';
import {AiSettingComponent} from './ai-setting/ai-setting.component';
import {GtmHoursComponent} from './gtm-hours/gtm-hours.component';
import {GtmCountriesComponent} from './gtm-countries/gtm-countries.component';
import {AssignSettingService} from '../../services/assign-settings/assign-setting.service';
import {ViewsControlComponent} from './views-control/views-control.component';
import {RegionsComponent} from './regions/regions.component';
import {AiSettingRetentionComponent} from './ai-setting-retention/ai-setting-retention.component';
import {select, Store} from '@ngrx/store';
import {GetActiveBoard, GetBoardId, GetRoleType} from '../../reducers/selectors/login.selector';
import {filter, takeUntil} from 'rxjs/operators';
import {AI_TYPE, TYPE_APPLICATIONS} from '../../constants/user';
import {ViewsControlRetentionComponent} from './views-control-retention/views-control-retention.component';
import {AiSettingInterviewComponent} from './ai-setting-interview/ai-setting-interview.component';
import * as LoginAction from '../../actions/login.actions';
import {AiSettingWhvComponent} from './ai-setting-whv/ai-setting-whv.component';
import {BlandComponent} from './bland/bland.component';
import {RegionSplitComponent} from './region-split/region-split.component';
import { MultiSplitComponent } from './multi-split/multi-split.component';

@Component({
    selector: 'app-assign-settings',
    templateUrl: './assign-settings.component.html',
    styleUrls: ['./assign-settings.component.less']
})
export class AssignSettingsComponent extends BaseComponent {

    public activeId = 0;

    public group: any = null;

    public subscribers: any = {
        get: null
    };

    public selectedPortal: Portal<any>;
    
    public activeBoard: any = null;

    public portals: any = [
        {
            label: 'AI Settings',
            icon: 'edit',
            show: () => true,
            fn: (i) => {
                if (this.activeBoard) {
                    this.selectedPortal = new ComponentPortal(this.getAiSettings(this.activeBoard.ai_type));

                    this.activeId = i;
                }
            }
        },
        {
            label: 'GMT Hours Settings',
            icon: 'edit',
            show: () => true,
            fn: (i) => {
                this.selectedPortal = new ComponentPortal(GtmHoursComponent);
                this.activeId = i;
            }
        },
        {
            label: 'GMT Countries Settings',
            icon: 'edit',
            show: () => true,
            fn: (i) => {
                this.selectedPortal = new ComponentPortal(GtmCountriesComponent);
                this.activeId = i;
            }
        },
        {
            label: 'Views Control',
            icon: 'edit',
            show: () => true,
            fn: (i) => {
                // const component: any = this.roleType === TYPE_APPLICATIONS ? ViewsControlRetentionComponent : ViewsControlComponent;

                this.selectedPortal = new ComponentPortal(ViewsControlRetentionComponent);
                this.activeId = i;
            }
        },
        {
            label: 'Regions Control',
            icon: 'edit',
            show: () => true,
            fn: (i) => {
                this.selectedPortal = new ComponentPortal(RegionsComponent);
                this.activeId = i;
            }
        },
        {
            label: 'Multi Split',
            icon: 'edit',
            show: () => true,
            fn: (i) => {
                this.selectedPortal = new ComponentPortal(MultiSplitComponent);
                this.activeId = i;
            }
        },
        {
            label: 'Bland',
            icon: 'edit',
            show: () => true,
            fn: (i) => {
                this.selectedPortal = new ComponentPortal(BlandComponent);
                this.activeId = i;
            }
        }
    ];

    public roleType: number;
 

    constructor(
        private service: AssignSettingService,
        private route: ActivatedRoute,
        private snack: MatSnackBar,
        public permissions: Permissions,
        private store: Store<any>
    ) {
        super();

        this.store.pipe(
            select(GetActiveBoard),
            takeUntil(this.destroy),
            filter(data => !!data)
        ).subscribe((board: any) => {
            this.activeBoard = board;

            if (board) {
                this.selectedPortal = new ComponentPortal(this.getAiSettings(board.ai_type));
            }
        });

        this.store.pipe(
            select(GetRoleType),
            takeUntil(this.destroy),
            filter(data => !!data)
        ).subscribe((type: number) => {
            this.roleType = type;
        });
    }

    private getAiSettings(ai_type: number): any {
        switch (ai_type) {
            case AI_TYPE.CONVERSION:
                return AiSettingComponent;
            case AI_TYPE.RETENTION:
                return AiSettingRetentionComponent;
            case AI_TYPE.WHV:
                return AiSettingWhvComponent;
            case AI_TYPE.INTERVIEW:
                return AiSettingInterviewComponent;
            default:
                return AiSettingComponent;
        }
    }

}
