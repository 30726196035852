import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';
import {SseService} from '../sse/sse.service';

@Injectable({
    providedIn: 'root'
})

export class LeadService {

    constructor(
        private api: ApiService,
        private sse: SseService
    ) {
    }

    public getLead(id: number): Observable<any> {
        return this.api.get('v1/lead/' + id, {
            params: {}
        });
    }

    public getLeads(data: any): Observable<any> {
        return this.api.post('v1/lead', {
            body: data,
        });
    }

    public getReassignToken(data: any): Observable<any> {
        return this.api.post('v1/lead/reassign-pool', {
            body: data,
        });
    }

    public reassignLeads(token: string): Observable<any> {
        return this.sse.get('events/reassign-pool', 'pool=' + token);
    }

    public syncLeads(params): Observable<any> {
        return this.api.post('v1/lead/sync', {
            body: params
        });
    }

    public syncLeadsData(body): Observable<any> {
        return this.api.post('v1/lead/sync-data', {
            body
        });
    }

    public changeStatus(data: any): Observable<any> {
        return this.api.post('v1/lead/assignable-lead', {
            body: data
        });
    }

    public getBlockedLeads(agentId): Observable<any> {
        return this.api.get('v1/lead/call-blocked-leads?fields=first_name,last_name,email,id,country_of_residence&agent_id='+ agentId, {});
    }

    public cleaningCounterAttempts(data: any): Observable<any> {
        return this.api.post('v1/lead/cleaning-counter', {
            body: {
                agent_id: data.agentId,
                lead_id: data.leadId
            }
        });
    }




    public notifications(): Observable<any> {
        return this.sse.get('events/notifications');
    }
}
