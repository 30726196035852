import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatChipInputEvent, MatDialogRef, MatSnackBar} from '@angular/material';
import {Unsubscribe} from '../../../classes/unsubscribe';
import { LeadService } from 'src/app/services/lead/lead.service';
import { GetActiveBoard } from 'src/app/reducers/selectors/login.selector';
import { Store, select } from '@ngrx/store';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CentrifugeService } from 'src/app/services/centrifuge/centrifuge.service';

@Component({
  selector: 'app-parse-entity-modal',
  templateUrl: './parse-entity-modal.component.html',
  styleUrls: ['./parse-entity-modal.component.less']
})
export class ParseEntityModalComponent extends Unsubscribe implements OnInit {

  public isLoading: boolean = false;

  public isProcessing: boolean = false;

  public model: 'leads' | 'applications' = 'leads';

  public leadNumbers: string[] = [];

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public progressList = [];

  public websocket = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public self: MatDialogRef<ParseEntityModalComponent>,
    private leadService: LeadService,
    private store: Store<any>,
    private centrifugeService: CentrifugeService,
    private ngZone: NgZone,
    private snack: MatSnackBar,
  ) {
    super();
  }

  ngOnInit() {
    if(this.data.lead_numbers && this.data.lead_numbers.length){
      this.leadNumbers = this.data.lead_numbers;
    }

    this.store.pipe(
      select(GetActiveBoard),
      takeUntil(this.destroy),
      filter(data => !!data)
    ).subscribe((board: any) => {
      if(board){
        this.model = board.id == 1 ? 'leads' : 'applications';
      }
    });
  }

  public syncLeadData(): void{
    this.isLoading = true;
    if(this.leadNumbers.length > 1000) this.leadNumbers.length = 1000;

    this.leadService.syncLeadsData({
      model: this.model,
      lead_numbers: this.leadNumbers
    }).pipe(
      finalize(() => {this.isLoading = false}),
      takeUntil(this.destroy)
    ).subscribe({
      next: (value) => {
        if(value && value.token){
          this.subscribeToCentrifugo(value.token);
        }
        else{
          this.closeModal();
        }
      },
      error: (err) => {
        this.closeModal();
      }
    })
  }

  private subscribeToCentrifugo(chanel: string): void {
    this.resetWebsocket();

    this.websocket = this.centrifugeService.listen(chanel).pipe(
      takeUntil(this.destroy)
    ).subscribe(({data}) => {
      this.ngZone.run(() => {
        this.progressList.push(data.message);

        this.isProcessing = data.status === 'Processing';
      });
    });
  }

  public add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.leadNumbers.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  public remove(fruit: any): void {
    const index = this.leadNumbers.indexOf(fruit);

    if (index >= 0) {
      this.leadNumbers.splice(index, 1);
    }
  }

  private resetWebsocket(): void{
    if(this.websocket){
      this.websocket.unsubscribe();
      this.websocket = null;
    }

    this.progressList = [];
  }

  private closeModal(): void{
    this.resetWebsocket();
    this.self.close();
    this.snack.open('Unexpected error occurred!', 'OK!', {duration: 10000});
  }
}
