import {AssignSettingsComponent} from './assign-settings.component';
import {NgModule} from '@angular/core';
import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {AiSettingComponent} from './ai-setting/ai-setting.component';
import {GtmHoursComponent} from './gtm-hours/gtm-hours.component';
import {GtmCountriesComponent} from './gtm-countries/gtm-countries.component';
import {
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatSelectModule, MatExpansionModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatBadgeModule
} from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoaderModule} from '../general/loader/loader.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ViewsControlComponent} from './views-control/views-control.component';
import {RegionsComponent} from './regions/regions.component';
import {AiSettingRetentionComponent} from './ai-setting-retention/ai-setting-retention.component';
import { ViewsControlRetentionComponent } from './views-control-retention/views-control-retention.component';
import { CreateViewControlComponent } from './create-view-control/create-view-control.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import { AiSettingInterviewComponent } from './ai-setting-interview/ai-setting-interview.component';
import { AiSettingWhvComponent } from './ai-setting-whv/ai-setting-whv.component';
import { BlandComponent } from './bland/bland.component';
import { NewBlandComponent } from './bland/new-bland/new-bland.component';
import { AiResultReviewComponent } from './ai-setting-retention/ai-result-review/ai-result-review.component';
import { SettingsFormComponent } from './ai-setting-retention/settings-form/settings-form.component';
import { RegionSplitComponent } from './region-split/region-split.component';
import { MultiSplitComponent } from './multi-split/multi-split.component';
import { InRangeInputModule } from '../conditions/in-range-input/in-range-input.module';

@NgModule({
    declarations: [
        AssignSettingsComponent,
        AiSettingRetentionComponent,
        AiSettingInterviewComponent,
        AiSettingWhvComponent,
        AiSettingComponent,
        GtmHoursComponent,
        GtmCountriesComponent,
        ViewsControlComponent,
        RegionsComponent,
        MultiSplitComponent,
        ViewsControlRetentionComponent,
        CreateViewControlComponent,
        BlandComponent,
        NewBlandComponent,
        AiResultReviewComponent,
        SettingsFormComponent,
        RegionSplitComponent,
    ],
    imports: [
        MatToolbarModule,
        PortalModule,
        MatListModule,
        MatIconModule,
        MatCardModule,
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        FormsModule,
        MatTooltipModule,
        MatBadgeModule,
        MatTabsModule,
        InRangeInputModule,
        MatAutocompleteModule,
        LoaderModule,
        DragDropModule,
        MatSelectModule,
        MatDatepickerModule,
        MatMenuModule,
        MatTableModule,
        MatExpansionModule
    ],
    exports: [
        AssignSettingsComponent
    ],
    entryComponents: [
        AiSettingComponent,
        AiSettingRetentionComponent,
        AiSettingInterviewComponent,
        AiSettingWhvComponent,
        GtmHoursComponent,
        GtmCountriesComponent,
        RegionsComponent,
        RegionSplitComponent,
        ViewsControlComponent,
        ViewsControlRetentionComponent,
        CreateViewControlComponent,
        MultiSplitComponent,
        BlandComponent,
        NewBlandComponent
    ]
})
export class AssignSettingsModule {
}
