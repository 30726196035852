import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsersComponent} from './users.component';
import {
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule, MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule, MatProgressSpinnerModule, MatSnackBarModule,
    MatSortModule,
    MatTableModule, MatToolbarModule
} from '@angular/material';
import {LoaderModule} from '../general/loader/loader.module';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {DeleteUserModule} from '../delete-user/delete-user.module';
import {DeleteUserComponent} from '../delete-user/delete-user.component';
import {EditUserComponent} from '../edit-user/edit-user.component';
import {MatSelectModule} from '@angular/material/select';


@NgModule({
    declarations: [
        UsersComponent
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        LoaderModule,
        MatIconModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonModule,
        MatToolbarModule,
        RouterModule,
        FormsModule,
        MatDialogModule,
        DeleteUserModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatSelectModule
    ],
    entryComponents: [
        DeleteUserComponent,
        EditUserComponent
    ]
})
export class UsersModule {
}
