import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {QueueService} from '../../../services/queue/queue.service';
import {SelectionModel} from '@angular/cdk/collections';
import BaseComponent from '../../base/base.component';
import {MatBottomSheet, MatDialog} from '@angular/material';
import {PhoneListComponent} from '../phone-list/phone-list.component';
import {ConfirmModalComponent} from '../../confirm-modal/confirm-modal.component';
import {SyncListComponent} from '../sync-list/sync-list.component';
import {Permissions} from '../../../helpers/Permissions';

@Component({
    selector: 'app-queue-table',
    templateUrl: './queue-table.component.html',
    styleUrls: ['./queue-table.component.less']
})

export class QueueTableComponent extends BaseComponent implements OnInit, OnChanges {

    public loader = true;

    @Input() public id: number;

    @Output() public change = new EventEmitter<any>();

    public toolbarMenu = [
        {
            id: 'sync_queue',
            label: 'Sync Queue',
            icon: 'import_export',
            show: this.permissions.can('phone_list', 'can_sync_phone_list'),
            fn: () => {
                const dialog = this.dialog.open(SyncListComponent, {
                    width: '600px',
                    data: {
                        id: this.queue.id,
                    }
                });
            }
        },
        {
            id: 'sync_selected',
            label: 'Sync Selected',
            icon: 'select_all',
            show: this.permissions.can('phone_list', 'can_sync_phone_list'),
            fn: () => {

                if (this.selection.selected.length <= 0) {
                    return;
                }

                this.dialog.open(SyncListComponent, {
                    width: '600px',
                    data: {
                        id: this.queue.id,
                        selected: this.selection.selected.map((select) => {
                            return select.id;
                        })
                    }
                });

            }
        }
    ];

    public queue = {
        id: 0,
        page: 0,
        page_size: 100,
        rows: 0,
        data: [],
        fields: [],
        info: {
            name: ''
        }
    };

    public filter = {};

    public sort = {};

    public resError = false;

    public columnsToDisplay = [];

    public selection = new SelectionModel<any>(true, []);

    constructor(
        private queueService: QueueService,
                private dialog: MatDialog,
        public permissions: Permissions
    ) {
        super();
        this.getQueueLeads();
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    public openPhoneList() {
        const dialog = this.dialog.open(PhoneListComponent, {
            width: '600px',
            data: {
                id: this.queue.id
            }
        });
    }

    public setInitial(id: number = 0) {
        this.queue = {
            id: id,
            page: 0,
            page_size: 100,
            rows: 0,
            data: [],
            fields: [],
            info: {
                name: ''
            }
        };
        this.columnsToDisplay = [];
        this.sort = {};
        this.filter = {};
        this.toggleLoader();
        this.getQueueLeads();
    }

    public choseMenu(option: any, queue: any): void {
        option.fn(queue);

    }

    public changeSort(event: any) {
        this.sort = {['sort[' + event.active + ']']: event.direction};
        this.queue.page = 0;
        this.toggleLoader();
        this.getQueueLeads();
    }

    public changePage(event: any): void {
        this.queue.page = event.pageIndex;
        this.queue.page_size = event.pageSize;
        this.toggleLoader();
        this.getQueueLeads();
    }

    public isAllSelected(): boolean {
        return this.selection.selected.length === this.queue.data.length;
    }

    public masterToggle(): void {
        this.isAllSelected() ?
            this.selection.clear() :
            this.queue.data.forEach(lead => this.selection.select(lead));
    }

    private getQueueLeads(): void {
        this.selection.clear();
        this.queueService.getQueueLeads(this.queue.id, Object.assign({
            page_size: this.queue.page_size,
            page: this.queue.page
        }, this.sort, this.filter)).subscribe((res) => {
            if (res.fields) {
                this.columnsToDisplay = res.fields.map((field) => {
                    return field.name;
                });
                this.columnsToDisplay.unshift('id');
            }
            this.queue = Object.assign(this.queue, res);
            if (this.queue.id !== this.id) {
                this.change.emit({
                    id: this.queue.id
                });
            }
            this.toggleLoader();
        }, () => {
            this.resError = true;
            this.toggleLoader();
        });
    }
}
