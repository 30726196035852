import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import _ from 'lodash';
import BaseComponent from '../../base/base.component';
import {AssignSettingService} from "../../../services/assign-settings/assign-setting.service";

const SettingRegions = 'regions';

@Component({
  selector: 'app-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.less']
})
export class RegionsComponent extends BaseComponent implements OnInit {

  public ngClasses: any = {
    active: false
  };

  public settings = [];

  public all_countries: any = [];

  constructor(private route: ActivatedRoute,
              private service: AssignSettingService,
              private snack: MatSnackBar) {

    super();
    this.loader = true;

    this.subscribers.settings = this.service.getAssignSetting({
      'setting': 'regions,countries'
    }).subscribe((res) => {
      if (res && res.countries) {
        this.all_countries = Object.values(res.countries);
      }
      if (res && res.regions) {
        this.settings = res.regions;
      }
      this.loader = false;
    }, err => {
      this.snack.open('Problem', 'Dismiss', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
      this.loader = false;
    });

  }

  ngOnInit() {

    setTimeout(() => {
      this.ngClasses.active = true;
    }, 100);

  }

  public addSetting() {
    this.settings.push({region_name: '', countries: []});
  }

  public onChange(evt, setting): void {
    setting.countries = evt.original || [];
  }

  get notAllFilledIn(): boolean {
    return this.settings.some(i => !(i.region_name !== '' && i.countries.length));
  }

  public save() {
    this.loader = true;

    const body = {
      setting_slug: SettingRegions,
      setting: {
        regions: this.settings
      }
    };
    this.service.updateAssignSetting(body).subscribe(res => {
      this.snack.open('Updated!', 'Ok!', {
        duration: 1000,
      });
      this.loader = false;
    }, error => {
      this.snack.open(_.get(error, ['error', SettingRegions], 'Something Went Wrong!'), 'Ok!', {
        duration: 2000,
      });
      this.loader = false;
    });
    this.loader = false;
  }

  public deleteSetting(index) {
    this.settings.splice(index, 1);
  }

}
