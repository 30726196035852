import {Component, ElementRef, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatChipInputEvent, MatDialogRef, MatSnackBar} from '@angular/material';
import {Unsubscribe} from '../../../classes/unsubscribe';
import {GetActiveBoard} from 'src/app/reducers/selectors/login.selector';
import {select, Store} from '@ngrx/store';
import {filter, finalize, takeUntil} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {CentrifugeService} from 'src/app/services/centrifuge/centrifuge.service';
import {ViewService} from 'src/app/services/view/view.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-parse-entity-modal',
  templateUrl: './find-entity-modal.component.html',
  styleUrls: ['./find-entity-modal.component.less']
})
export class FindEntityModalComponent extends Unsubscribe implements OnInit {

  @ViewChild('list') list: ElementRef;

  public isLoading = false;

  public isProcessing = false;

  public boardId: number;

  public leadNumbers: string[] = [];

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public progressList = [];

  public websocket = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public self: MatDialogRef<FindEntityModalComponent>,
    private viewService: ViewService,
    private store: Store<any>,
    private centrifugeService: CentrifugeService,
    private sanitizer: DomSanitizer,
    private ngZone: NgZone,
    private snack: MatSnackBar,
  ) {
    super();
  }

  ngOnInit() {
    if (this.data.lead_numbers && this.data.lead_numbers.length) {
      this.leadNumbers = this.data.lead_numbers;
    }

    this.store.pipe(
      select(GetActiveBoard),
      takeUntil(this.destroy),
      filter(data => !!data)
    ).subscribe((board: any) => {
      if (board) {
        this.boardId = board.id;
      }
    });
  }

  public syncLeadData(): void {
    this.isLoading = true;

    if (this.leadNumbers.length > 1000) {
      this.leadNumbers.length = 1000;
    }

    this.viewService.findViewClients({
      board_id: this.boardId,
      lead_numbers: this.leadNumbers
    }).pipe(
      finalize(() => {
        this.isLoading = false;
      }),
      takeUntil(this.destroy)
    ).subscribe({
      next: (value) => {
        if (value && value.channel) {
          this.subscribeToCentrifugo(value.channel);
        } else {
          this.closeModal();
        }
      },
      error: (err) => {
        this.closeModal();
      }
    });
  }

  public add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.leadNumbers.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  public remove(item: any): void {
    const index = this.leadNumbers.indexOf(item);

    if (index >= 0) {
      this.leadNumbers.splice(index, 1);
    }
  }

  private subscribeToCentrifugo(chanel: string): void {
    this.resetWebsocket();
    this.isProcessing = true;

    this.websocket = this.centrifugeService.listen(chanel).pipe(
      takeUntil(this.destroy)
    ).subscribe(({data}) => {
      this.ngZone.run(() => {
        this.progressList.push(this.getParsedMessage(data.message));

        this.updateScroll();

        this.isProcessing = data.status === 'started' || data.status === 'processing';
      });
    });
  }

  private updateScroll() {
    setTimeout(() => {
      if (this.list && this.list.nativeElement) {
        this.list.nativeElement.scrollTo(0, this.list.nativeElement.scrollHeight);
      }
    });
  }

  private getParsedMessage(message: any): SafeHtml {
    if (message && message.toLowerCase().includes('finished')) {
      message = '✅ ' + message;
    }

    return this.sanitizer.bypassSecurityTrustHtml(message);
  }

  private resetWebsocket(): void {
    if (this.websocket) {
      this.websocket.unsubscribe();
      this.websocket = null;
    }

    this.progressList = [];
  }

  private closeModal(): void {
    this.resetWebsocket();
    this.self.close();
    this.snack.open('Unexpected error occurred!', 'OK!', {duration: 10000});
  }
}
