import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { LoginModule } from './components/login/login.module';
import { LoaderModule } from './components/general/loader/loader.module';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { HeaderModule } from './components/general/header/header.module';
import { SideNavModule } from './components/general/side-nav/side-nav.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule} from '@angular/material';
import { UsersModule } from './components/users/users.module';
// import { CreateUserModule } from './components/create-user/create-user.module';
import { DeleteUserModule } from './components/delete-user/delete-user.module';
import { EditUserModule } from './components/edit-user/edit-user.module';
import { AgentShiftsModule } from './components/agent-shifts/agent-shifts.module';
import { ViewModule } from './components/view/view.module';
import { CreateViewModule } from './components/create-view/create-view.module';
import { ErrorDialogModule } from './components/general/error-dialog/error-dialog.module';
import { ListViewsModule } from './components/list-views/list-views.module';
import { DeleteViewModule } from './components/delete-view/delete-view.module';
import { NoResultModule } from './components/general/no-result/no-result.module';
import { EditViewModule } from './components/edit-view/edit-view.module';
import { LeadsInfoTableModule } from './components/leads-info-table/leads-info-table.module';
import { AgentsModule } from './components/agents/agents.module';
import { StoreModule } from '@ngrx/store';
import { reducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AgentsAssignModule } from './components/agents-assign/agents-assign.module';
import { DataPicketCampaignsModule } from './components/data-picket-campaigns/data-picket-campaigns.module';
import { LeadsInfoAmchartModule } from './components/leads-info-amchart/leads-info-amchart.module';
import { ViewLeadInfoModule } from './components/view-lead-info/view-lead-info.module';
import { AssignedToModule } from './components/filters/assigned-to/assigned-to.module';
import { AgentsSheetModule } from './components/agents-sheet/agents-sheet.module';
import { CountryOfBirthModule } from './components/filters/country-of-birth/country-of-birth.module';
import { CountryOfResidenceModule } from './components/filters/country-of-residence/country-of-residence.module';
import { TouchedModule } from './components/filters/touched/touched.module';
import { StatusReasonModule } from './components/filters/status-reason/status-reason.module';
import { ReverseModule } from './components/filters/reverse/reverse.module';
import { MultipleSelectorModule } from './components/general/multiple-selector/multiple-selector.module';
import { DisqualifiedModule } from './components/filters/disqualified/disqualified.module';
import { FunnelStatusReasonModule } from './components/filters/funnel-status-reason/funnel-status-reason.module';
import { NetWorthModule } from './components/filters/net-worth/net-worth.module';
import { PreviousOwnerModule } from './components/filters/previous-owner/previous-owner.module';
import { VisaTypeModule } from './components/filters/visa-type/visa-type.module';
import { NationalityModule } from './components/filters/nationality/nationality.module';
import { MaritalStatusModule } from './components/filters/marital-status/marital-status.module';
import { DateRangeSelectModule } from './components/filters/date-range-select/date-range-select.module';
import { ConfirmModalModule } from './components/confirm-modal/confirm-modal.module';
import { AccessDeniedModule } from './components/general/access-denied/access-denied.module';
import { AgentsKPIModule } from './components/agents-kpi/agents-kpi.module';
import { LoaderFullModule } from './components/general/loader-full/loader-full.module';
import { ReAssignPoolModule } from './components/re-assign-pool/re-assign-pool.module';
import { ProgressLoaderModule } from './components/general/progress-loader/progress-loader.module';
import { CallActivityModule } from './components/call-activity/call-activity.module';
import { CallActivityInfoModule } from './components/call-activity-info/call-activity-info.module';
import { AssignReportModule } from './components/assign-report/assign-report.module';
import { ContactChartModule } from './components/assign-report/contact-chart/contact-chart.module';
import { FreshChartModule } from './components/assign-report/fresh-chart/fresh-chart.module';
import { QueueModule } from './components/queue/queue.module';
import { ActionModalModule } from './components/queue/action-modal/action-modal.module';
import { PhoneListModule } from './components/queue/phone-list/phone-list.module';
import { CreateEditModalModule } from './components/queue/create-edit-modal/create-edit-modal.module';
import { SyncListModule } from './components/queue/sync-list/sync-list.module';
import { AgentStatisticsModule } from './components/agent-statistics/agent-statistics.module';
import { MainComponent } from './layouts/main/main.component';
import { GeneralComponent } from './layouts/general/general.component';
import { AgentSettingsModule } from './components/agent-settings/agent-settings.module';
import { CloneViewModule } from './components/clone-view/clone-view.module';
import { HistoryModule } from './components/history/history.module';
import { ReassignModule } from './components/notifications/reassign/reassign.module';
import { AssignModule } from './components/notifications/assign/assign.module';
import { ContactReportModule } from './components/contact-report/contact-report.module';
import { CallReportModule } from './components/call-report/call-report.module';
import { AgentGoalsModule } from './components/agent-goals/agent-goals.module';
import { GeneralSettingsModule } from './components/general-settings/general-settings.module';
import { HolidaysModule } from './components/holidays/holidays.module';
// import { ChatModule } from './components/chat/chat.module';
import { RolesModule } from './components/roles/roles.module';
import { ShiftsModule } from './components/shifts/shifts.module';
import { EditShiftModule } from './components/shifts/edit-shift/edit-shift.module';
import { Permissions } from './helpers/Permissions';
import {AssignSettingsModule} from './components/assign-settings/assign-settings.module';
import {AssignSuggestionModule} from './components/assign-suggestion/assign-suggestion.module';
import { BulkAssignModule } from './components/bulk-assign/bulk-assign.module';
import { BulkAssignIdModule } from './components/bulk-assing-id/bulk-assing-id.module';
import {LOGIN_FEATURE_NAME, LoginReducer} from './reducers/login.reducer';
import {DepartmentRolesModule} from './components/department-roles/department-roles.module';
import {Unsubscribe} from './classes/unsubscribe';
import {SelectBoard} from './classes/selectBoard';
import {FooterModule} from './components/general/footer/footer.module';
import {HeaderGeneralLayoutModule} from './components/general/header-general-layout/header-general-layout.module';
import { EmptyComponent } from './layouts/empty/empty.component';

@NgModule({
    declarations: [
        AppComponent,
        NotfoundComponent,
        MainComponent,
        GeneralComponent,
        Unsubscribe,
        SelectBoard,
        EmptyComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,

        StoreModule.forFeature(LOGIN_FEATURE_NAME, LoginReducer),
        RouterModule.forRoot(appRoutes),
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
        }),
        HeaderGeneralLayoutModule,
        AppRoutingModule,
        LoginModule,
        LoaderModule,
        DashboardModule,
        HeaderModule,
        FooterModule,
        SideNavModule,
        MatSidenavModule,
        UsersModule,
        // CreateUserModule,
        DeleteUserModule,
        EditUserModule,
        AgentShiftsModule,
        ViewModule,
        CreateViewModule,
        ErrorDialogModule,
        ListViewsModule,
        DeleteViewModule,
        NoResultModule,
        EditViewModule,
        LeadsInfoTableModule,
        AgentsModule,
        DataPicketCampaignsModule,
        AgentsAssignModule,
        LeadsInfoAmchartModule,
        ViewLeadInfoModule,
        AssignedToModule,
        AgentsSheetModule,
        CountryOfBirthModule,
        CountryOfResidenceModule,
        TouchedModule,
        StatusReasonModule,
        ReverseModule,
        MultipleSelectorModule,
        DisqualifiedModule,
        FunnelStatusReasonModule,
        NetWorthModule,
        PreviousOwnerModule,
        VisaTypeModule,
        NationalityModule,
        MaritalStatusModule,
        DateRangeSelectModule,
        ConfirmModalModule,
        AccessDeniedModule,
        AgentsKPIModule,
        LoaderFullModule,
        ReAssignPoolModule,
        ProgressLoaderModule,
        CallActivityModule,
        CallActivityInfoModule,
        AssignReportModule,
        ContactChartModule,
        FreshChartModule,
        QueueModule,
        ActionModalModule,
        PhoneListModule,
        CreateEditModalModule,
        SyncListModule,
        AgentStatisticsModule,
        AgentSettingsModule,
        CloneViewModule,
        HistoryModule,
        ReassignModule,
        AssignModule,
        ContactReportModule,
        CallReportModule,
        AgentGoalsModule,
        GeneralSettingsModule,
        HolidaysModule,
        // ChatModule,
        RolesModule,
        ShiftsModule,
        EditShiftModule,
        AssignSettingsModule,
        AssignSuggestionModule,
        BulkAssignModule,
        BulkAssignIdModule,
        // SelectDashboardModule,
        DepartmentRolesModule,
    ],
    providers: [
        Permissions
    ],
    bootstrap: [
        AppComponent,
    ]
})

export class AppModule {
}
