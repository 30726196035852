import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ViewComponent} from './view.component';
import {
    MatAutocompleteModule, MatBadgeModule,
    MatButtonModule, MatButtonToggleModule,
    MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialogModule, MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule, MatMenuModule,
    MatPaginatorModule, MatProgressBarModule,
    MatSortModule,
    MatTableModule, MatTabsModule,
    MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {LoaderModule} from '../general/loader/loader.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ListViewsComponent} from '../list-views/list-views.component';
import {NoResultModule} from '../general/no-result/no-result.module';
import {AgentsModule} from '../agents/agents.module';
import {AgentsAssignModule} from '../agents-assign/agents-assign.module';
import {ViewLeadInfoComponent} from '../view-lead-info/view-lead-info.component';
import {AssignedToModule} from '../filters/assigned-to/assigned-to.module';
import {TouchedModule} from '../filters/touched/touched.module';
import {CountryOfResidenceModule} from '../filters/country-of-residence/country-of-residence.module';
import {CountryOfBirthModule} from '../filters/country-of-birth/country-of-birth.module';
import {StatusReasonModule} from '../filters/status-reason/status-reason.module';
import {ReverseModule} from '../filters/reverse/reverse.module';
import {DisqualifiedModule} from '../filters/disqualified/disqualified.module';
import {FunnelStatusReasonModule} from '../filters/funnel-status-reason/funnel-status-reason.module';
import {NetWorthModule} from '../filters/net-worth/net-worth.module';
import {PreviousOwnerModule} from '../filters/previous-owner/previous-owner.module';
import {VisaTypeModule} from '../filters/visa-type/visa-type.module';
import {MaritalStatusModule} from '../filters/marital-status/marital-status.module';
import {NationalityModule} from '../filters/nationality/nationality.module';
import {DateRangeSelectModule} from '../filters/date-range-select/date-range-select.module';
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component';
import {LoaderFullModule} from '../general/loader-full/loader-full.module';
import {ReAssignPoolModule} from '../re-assign-pool/re-assign-pool.module';
import {AssignReportModule} from '../assign-report/assign-report.module';
import {ViewTableSkeletonModule} from '../view-table-skeleton/view-table-skeleton.module';
import {ViewStatisticsModule} from '../view-statistics/view-statistics.module';
import {AutocompleteModule} from '../filters/autocomplete/autocomplete.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatVsTableModule} from 'mat-vs-table';
import {AssignLeadsModalComponent} from '../assign-leads-modal/assign-leads-modal.component';
import {AssignLeadsModalModule} from '../assign-leads-modal/assign-leads-modal.module';
import {JsonFieldModule} from './json-field/json-field.module';
import { CountrySplitModalComponent } from './country-split-modal/country-split-modal.component';
import { ParseEntityModalComponent } from './parse-entity-modal/parse-entity-modal.component';
import { BinFieldComponent } from './bin-field/bin-field.component';
import {FindEntityModalComponent} from './find-entity-modal/find-entity-modal.component';

@NgModule({
    declarations: [
        ViewComponent,
        CountrySplitModalComponent,
        ParseEntityModalComponent,
        FindEntityModalComponent,
        BinFieldComponent
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatIconModule,
        MatToolbarModule,
        LoaderModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        RouterModule,
        MatCheckboxModule,
        MatButtonModule,
        MatDatepickerModule,
        NoResultModule,
        AgentsModule,
        AgentsAssignModule,
        AssignedToModule,
        TouchedModule,
        CountryOfResidenceModule,
        CountryOfBirthModule,
        StatusReasonModule,
        ReverseModule,
        DisqualifiedModule,
        FunnelStatusReasonModule,
        NetWorthModule,
        PreviousOwnerModule,
        VisaTypeModule,
        NationalityModule,
        MaritalStatusModule,
        MatDividerModule,
        MatMenuModule,
        MatDialogModule,
        DateRangeSelectModule,
        MatChipsModule,
        LoaderFullModule,
        ReAssignPoolModule,
        MatTabsModule,
        MatBadgeModule,
        AssignReportModule,
        MatProgressBarModule,
        ViewTableSkeletonModule,
        ViewStatisticsModule,
        AutocompleteModule,
        MatTooltipModule,
        ScrollingModule,
        MatVsTableModule,
        MatMenuModule,
        AssignLeadsModalModule,
        MatButtonToggleModule,
        JsonFieldModule,
        MatChipsModule,
    ],
    exports: [
        ViewComponent
    ],
    entryComponents: [
        ListViewsComponent,
        ViewLeadInfoComponent,
        ConfirmModalComponent,
        AssignLeadsModalComponent,
        CountrySplitModalComponent,
        ParseEntityModalComponent,
        FindEntityModalComponent
    ]
})

export class ViewModule {
}
