import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material';

@Component({
    selector: 'app-bin-field',
    templateUrl: './bin-field.component.html',
    styleUrls: ['./bin-field.component.less']
})
export class BinFieldComponent implements OnInit {

    @Input() name: string;

    @Input()
    public set data(val) {
        this._data = this.isJson(val) || this.data;
    }

    private _data: any = [];

    public get data(): any[] {
        return this._data;
    }

    public columnsToDisplay = {
      'bin': 'Bin',
      'card': 'Card',
      'card_type': 'Type',
      'card_class': 'Class',
      'country': 'Country',

      'products': 'Products',
      'paid_amount': 'Paid Amount',
      'method': 'Method',
    };

    ngOnInit() {
    }

    private isJson(data): boolean {
        try {

            return JSON.parse(data);

        } catch (e) {

            return false;

        }
    }


}
