import {Component, Inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import BaseComponent from '../base/base.component';
import {UserService} from '../../services/user/user.service';
import {MAT_DIALOG_DATA, MatChipInputEvent, MatDialogRef, MatSnackBar} from '@angular/material';
import {AgentService} from '../../services/agent/agent.service';
import {User} from '../../services/user/user';
import {RolesService} from '../../services/roles/roles.service';
import {select, Store} from '@ngrx/store';
import {filter, takeUntil} from 'rxjs/operators';
import {GetBoardId} from '../../reducers/selectors/login.selector';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {ipRex} from '../../constants/regex';
import storage from 'store';
import {passwordGenerator} from '../../helpers/passwordGenerator';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.less']
})

export class EditUserComponent extends BaseComponent implements OnInit {

  public password = false;
  public confirm_password = false;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public autoCompleteLoader: any = false;

  public agents: Observable<any>;

  public roles: any[] = [];

  public newIp = new FormControl('', [Validators.pattern(ipRex)])

  public editUser: FormGroup = new FormGroup({
    username: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
      Validators.pattern(/^[a-zA-z0-9-]{3,100}$/)
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
      Validators.email
    ]),
    password: new FormControl('', [
      Validators.maxLength(100)
    ]),
    confirm_password: new FormControl('', [
      Validators.maxLength(100)
    ]),
    full_name: new FormControl('', [
      Validators.required,
    ]),
    crm_username: new FormControl('', [
      Validators.required
    ]),
    crm_id: new FormControl('', []),
    role_id: new FormControl('', []),
    board_id: new FormControl('', []),
    ips: new FormControl([], []),
  });

  public defaultRole: any = '';

  private timeout: any;

  private activeAgent: any = {
    crm_username: ''
  };

  public options: any = [];

  constructor(
      private agentService: AgentService,
      private userService: UserService,
      private snackBar: MatSnackBar,
      private self: MatDialogRef<any>,
      @Inject(MAT_DIALOG_DATA) public user: User,
      public rolesService: RolesService,
      private store: Store<any>
  ) {
    super();

    this.store.pipe(
        select(GetBoardId),
        takeUntil(this.destroy),
        filter(data => !!data)
    ).subscribe((id: number) => {
      this.editUser.get('board_id').setValue(id);
    });

    const roles = storage.get('roles') || [];

    if (!roles.length) {
      this.rolesService.getRoles().pipe(
          takeUntil(this.destroy)
      ).subscribe((response) => {
        this.roles = response.data;
        localStorage.setItem('roles', JSON.stringify(this.roles));
        this.fillUserData();
      });
    } else {
      this.roles = roles;

      this.fillUserData();
    }
  }

  ngOnInit(): void {
    this.editUser.get('crm_username').valueChanges.pipe(
        takeUntil(this.destroy)
    ).subscribe((value) => {
      if (value) {
        if (this.activeAgent && value !== this.activeAgent.crm_username) {
          this.editUser.get('crm_id').setValue('');
          this.editUser.get('crm_id').updateValueAndValidity();
        }
      }
    });
  }

  public fillUserData(): void {
    if (this.user) {
      for (const name in this.user) {
        if (this.editUser.get(name)) {
          this.editUser.get(name).setValue(this.user[name]);
        }
      }

      this.getDefaultRole(this.roles);
    }
  }

  public autoCompleteChange(event): void {
    // if (event.data !== null) {
      this.autoCompleteLoader = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getAutoCompleteData();
      }, 500);
    // }
  }

  public getAutoCompleteData(): void {
    this.agents = new Observable<any>((observer) => {
      this.getAgents(this.editUser.get('crm_username').value).pipe(
          takeUntil(this.destroy)
      ).subscribe((response) => {
        this.autoCompleteLoader = false;
        observer.next(response.data);
        observer.complete();
      });
      return {
        unsubscribe() {
        }
      };
    });
  }

  public autoCompleteSelected(event, agent): void {
    if (agent) {
      this.activeAgent = agent;
      this.editUser.get('crm_id').setValue(agent.crm_id);
    }
  }

  public changeUser(): any {

    if (!this.editUser.valid) {
      return;
    }

    this.showLoader();

    if (this.user) {
      this.update();
    } else {
      this.create();
    }
  }

  public create(): any {
    this.userService.createUser(this.editUser.value).pipe(
        takeUntil(this.destroy)
    ).subscribe(() => {
      this.self.close('Updated!');

      this.snackBar.open('User have been created!', 'Dismiss', {
        duration: 3000,
      });

      this.hideLoader();
    }, (error) => {
      for (const name in error.error) {
        if (error.error.hasOwnProperty(name)) {
          this.editUser.get(name).setErrors({backend: error.error[name]});
        }
      }

      this.hideLoader();
    });
  }

  public update(): any {
    this.userService.editUser(this.user.id, this.editUser.value).pipe(
        takeUntil(this.destroy)
    ).subscribe(() => {
      this.self.close('Updated!');

      this.snackBar.open('User have been edited!', 'Dismiss', {
        duration: 3000,
      });

      this.hideLoader();
    }, (error) => {
      for (const name in error.error) {
        if (error.error.hasOwnProperty(name) && this.editUser.get(name)) {
          this.editUser.get(name).setErrors({backend: error.error[name]});
        }
      }

      this.hideLoader();
    });
  }

  private getAgents(name): Observable<any> {
    if (!name) {
      this.options = [];
      return;
    }
    const options = {
      'type': '',
      'page': 0,
      'page_size': 10,
      'sort': 'id=desc',
      'filter[general]': name
    };
    return this.agentService.filterAgents(options);
  }

  public getDefaultRole(newRoles) {
    const role: any = newRoles.find(({id}) => id === Number(this.user.role_id));
    this.defaultRole = role ? role.id : null;
    this.editUser.get('role_id').setValue(this.defaultRole);
  }

  public add(event: MatChipInputEvent): void {
    const input = event.input;
    const value: any = event.value;

    if (this.newIp.valid) {
      if ((value || '').trim()) {
        this.editUser.get('ips').value.push(value.trim());
      }

      if (input) {
        input.value = '';
      }
    }
  }

  public remove(index: number): void {
    this.editUser.get('ips').value.splice(index, 1);
  }

  public generatePassword(): void {
    const password = passwordGenerator();

    this.editUser.get('password').setValue(password);
    this.editUser.get('confirm_password').setValue(password);
  }
}
