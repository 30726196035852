import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material';

@Component({
  selector: 'app-json-field',
  templateUrl: './json-field.component.html',
  styleUrls: ['./json-field.component.less']
})
export class JsonFieldComponent implements OnInit {

  @Input() data: any;

  @Input() name: string;

  @Input() columnsToDisplay: {label: string, value: string}[] = null;

  public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  public displayedColumns: string[] = [];

  public list: string[];

  ngOnInit() {
    const data = this.isJson(this.data) || this.data;

    if (data && data.length > 0) {
      if (this.columnsToDisplay) {
        Object.keys(this.columnsToDisplay).forEach(key => this.displayedColumns.push(key));
      } else {
        Object.keys(data[0]).forEach(key => this.displayedColumns.push(key));
      }

      if (typeof data[0] === 'object') {

        this.dataSource.data = data;

      } else {

        this.list = data;

      }
    }
  }

  private isJson(data): boolean {
    try {

      return JSON.parse(data);

    } catch (e) {

      return false;

    }
  }

}
