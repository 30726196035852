import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AssignSettingService} from '../../../services/assign-settings/assign-setting.service';
import {MatBottomSheet, MatSnackBar} from '@angular/material';
import BaseComponent from '../../base/base.component';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import {filter, finalize, takeUntil} from 'rxjs/operators';
import { MultipleSelectorComponent } from '../../general/multiple-selector/multiple-selector.component';
import { select, Store } from '@ngrx/store';
import { GetActiveBoard } from 'src/app/reducers/selectors/login.selector';

const SettingName = 'multi_split';

@Component({
  selector: 'app-multi-split',
  templateUrl: './multi-split.component.html',
  styleUrls: ['./multi-split.component.less']
})
export class MultiSplitComponent extends BaseComponent implements OnInit {

  public readonly BOARD_TYPES = {
    CONVERSION: 1,
    RETENTION: 2
  }

  public ngClasses: any = {
    active: false
  };

  public calculationTypes = [
    {
      label: 'Percent',
      value: 'percent'
    }
  ];

  public settings = [];
  public settingsGeneral = {
    active: false,
		default_value: null
  };

  public countries = [];
  public programs = [];
  public products = [];

  public activeBoard: any = null;

  constructor(
      private route: ActivatedRoute,
      private service: AssignSettingService,
      private bottomSheet: MatBottomSheet,
      private snack: MatSnackBar,
      private store: Store<any>
  ) {
    super();

    this.loader = true;

    this.store.pipe(
      select(GetActiveBoard),
      takeUntil(this.destroy),
      filter(data => !!data)
    ).subscribe((board: any) => {
      console.log(board)
      this.activeBoard = board;
    });

    this.service.getAssignSetting({
      'setting': `${SettingName}, programs, products, countries`
    }).pipe(
        finalize(() => this.loader = false)
    ).subscribe((res) => {
      if(res) {
        if (res[SettingName]) {
          this.settingsGeneral = res[SettingName].general_params || this.settingsGeneral;
          this.settings = res[SettingName].splits || [];
        }

        this.countries = Object.values(res.countries || {});
        this.programs = res.programs || [];
        this.products = res.products || [];
      }
    }, err => {
      this.snack.open('Problem', 'Dismiss', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    });
  }

  public saveTooltip = 'Click to Save';

  ngOnInit() {
    setTimeout(() => {
      this.ngClasses.active = true;
    }, 100);
  }

  get isNotAllFilledIn(): boolean {
    const isDefaultValueSetIfNeeded = this.settingsGeneral.active ? Number.isInteger(this.settingsGeneral.default_value) : true;
    const hasAtLeastOneIfNeeded = this.settingsGeneral.active ? this.settings.length > 0 : true;
    
    if(!isDefaultValueSetIfNeeded) this.saveTooltip = 'Default value is required';

    if(!hasAtLeastOneIfNeeded) this.saveTooltip = 'Add at least one split';

    const someRequiredFieldEmpty = this.settings.some(i => {
      const atLeastRegionOrProgramorProduct = Object.keys(i.fields).some(key => i.fields[key] && i.fields[key].length);
      const isSomeParamsNotFilledIn = Object.keys(i.params).some(key => i.params[key] == null);

      if(!isSomeParamsNotFilledIn) this.saveTooltip = 'Please fill in required fields';
      if(!atLeastRegionOrProgramorProduct) this.saveTooltip = 'At least one of countries/program/products should be selected';

      return !(atLeastRegionOrProgramorProduct && !isSomeParamsNotFilledIn);
    });

    const result = !isDefaultValueSetIfNeeded || !hasAtLeastOneIfNeeded || someRequiredFieldEmpty;

    if(!result) this.saveTooltip = 'Click to Save';

    return result;
  }

  public addSetting() {
    this.settings.push({
      fields: {
        countries: [],
        programs: [],
        products: []
      },
      params: {
        match_value: 2,
        amount: null,
        not_match_value: 1,
        calculation_type: 'percent'
      }
    });
  }

  public save() {
    this.loader = true;

    let hasErr = false;

    _forEach(this.settings, el => {
      Object.keys(el.params).forEach(key => {
        if (key !== 'calculation_type' && el.params[key] < 0 || el.params[key] > 100) {
            this.snack.open(key + ' is invalid', 'Ok!', {
              duration: 1000,
            });

            hasErr = true;
            return;
        }
      });
    });

    if (hasErr) {
      this.loader = false;
      return;
    }

    const body = {
      setting_slug: SettingName,
      setting: {
        [SettingName]: {
          general_params: this.settingsGeneral,
          splits: this.settings
        }
      }
    };

    this.service.updateAssignSetting(body).pipe(
        finalize(() => this.loader = false)
    ).subscribe(res => {
      this.snack.open('Updated!', 'Ok!', {
        duration: 1000,
      });
    }, error => {
      this.snack.open(_get(error, ['error', SettingName], 'Something Went Wrong!'), 'Ok!', {
        duration: 2000,
      });
    });
  }

  public onChange(evt, setting, key): void {
    setting.fields[key] = evt.original || [];
  }

  public deleteSetting(index) {
    this.settings.splice(index, 1);
  }
}
