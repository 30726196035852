import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {PhoneListService} from '../../../services/phone-list/phone-list.service';
import BaseComponent from '../../base/base.component';
import {ConfirmModalComponent} from '../../confirm-modal/confirm-modal.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ErrorDialogComponent} from '../../general/error-dialog/error-dialog.component';
import {AssignProgressModalComponent} from '../../../pages/ai-assign/components/manage/settings/agent-status-modal/assign-progress-modal/assign-progress-modal.component';

@Component({
    selector: 'app-sync-list',
    templateUrl: './sync-list.component.html',
    styleUrls: ['./sync-list.component.less']
})
export class SyncListComponent extends BaseComponent implements OnInit {

    public selectLists: FormGroup;

    public loader: any = true;

    public lists: any = {
        data: [],
        page_size: 100,
        page: 0,
        rows: 0,
    };

    public disabled: any = false;

    public select: any = [];

    public progress = {
        loader: false,
        percent: 0,
        message: ''
    };

    private responseHandlers = {
        successAll: (response) => {
            this.showProgress(response.chanel, () => {
                this.self.close('List Synchronized!');
                //
                // this.snackBar.open('List successfully Synchronized!', 'Dismiss', {
                //     duration: 3000,
                // });
            });
        },
        successSelected: (response) => {
            this.self.close('List Synchronized!');

            this.snackBar.open(response.data.success + 'lead(s) successfully Synchronized!', 'Dismiss', {
                duration: 3000,
            });
        },
        error: () => {
            this.dialog.open(ErrorDialogComponent, {
                data: {
                    title: 'View can not be created',
                    message: 'Please contact your Administrator!'
                }
            });
        },
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private phoneList: PhoneListService,
        private dialog: MatDialog,
        private self: MatDialogRef<any>,
        private snackBar: MatSnackBar,
    ) {
        super();

        this.selectLists = new FormGroup({
            id: new FormControl('', [
                Validators.required
            ]),
        });
    }

    ngOnInit() {
        this.getLists();

    }

    public getLists() {
        this.phoneList.getPhoneLists(this.data.id, {
            page: this.lists.page,
            page_size: this.lists.page_size,
            ['sort[id]']: 'desc'
        }).subscribe((response) => {
            this.lists = Object.assign({}, this.lists, response);

            this.toggleLoader();
        });
    }

    public saveView(event): void {
        event.preventDefault();

        if (!this.selectLists.valid) {
            return;
        }

        this.selectList(this.lists.data.filter((item) => {
            return item.id === parseInt(this.selectLists.value.id, 10);
        }).pop());
    }


    public selectList(list): void {
        const dialog = this.dialog.open(ConfirmModalComponent, {
            width: '450px',
            data: {
                title: '' + list.name + '',
                description: ' Are you sure want sync ' + list.name + ' list?'
            }
        });
        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this.toggleLoader();
                this.disabled = true;
                const listId = list.id;
                if (this.data['selected']) {
                    this.phoneList.syncSelected(listId, {
                        leads: this.data.selected
                    }).subscribe(this.responseHandlers.successSelected, this.responseHandlers.error);
                } else {
                    this.phoneList.syncAll(listId).subscribe(this.responseHandlers.successAll, this.responseHandlers.error);
                }
            }
        });
    }

    private showProgress(chanel: string, callback?: () => void): void {
        this.dialog.open(AssignProgressModalComponent, {
            width: '550px',
            autoFocus: false,
            data: {
                title: 'Sync Progress',
                chanel
            }
        }).afterClosed().subscribe(() => {
            if (callback) {
                callback();
            }
        });
    }

}
