import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UsersComponent } from './components/users/users.component';
import { AgentShiftsComponent } from './components/agent-shifts/agent-shifts.component';
import { CreateViewComponent } from './components/create-view/create-view.component';
import { EditViewComponent } from './components/edit-view/edit-view.component';
import { AccessDeniedComponent } from './components/general/access-denied/access-denied.component';
import { ReAssignPoolComponent } from './components/re-assign-pool/re-assign-pool.component';
import { AssignReportComponent } from './components/assign-report/assign-report.component';
import { QueueComponent } from './components/queue/queue.component';
import { AgentStatisticsComponent } from './components/agent-statistics/agent-statistics.component';
import { MainComponent } from './layouts/main/main.component';
import { GeneralComponent } from './layouts/general/general.component';
import { AgentSettingsComponent } from './components/agent-settings/agent-settings.component';
import { HistoryComponent } from './components/history/history.component';
import { ContactReportComponent } from './components/contact-report/contact-report.component';
import { CallReportComponent } from './components/call-report/call-report.component';
import { AgentGoalsComponent } from './components/agent-goals/agent-goals.component';
import { GeneralSettingsComponent } from './components/general-settings/general-settings.component';
import { HolidaysComponent } from './components/holidays/holidays.component';
import { RolesComponent } from './components/roles/roles.component';
import { ShiftsComponent } from './components/shifts/shifts.component';
import { EditShiftComponent } from './components/shifts/edit-shift/edit-shift.component';
import { AgentsKPIComponent } from './components/agents-kpi/agents-kpi.component';
import { PermissionsComponent } from './components/roles/permissions/permissions.component';
import { AssignSettingsComponent } from './components/assign-settings/assign-settings.component';
import { AssignSuggestionComponent } from './components/assign-suggestion/assign-suggestion.component';
import { BulkAssignComponent } from './components/bulk-assign/bulk-assign.component';
import { BulkAssignIdComponent } from './components/bulk-assing-id/bulk-assing-id.component';
import {DepartmentRolesComponent} from './components/department-roles/department-roles.component';
import {BoardGuardService} from './services/board-guard/board-guard.service';
import {EmptyComponent} from './layouts/empty/empty.component';

export const appRoutes: Routes = [
    {
        path: '', component: MainComponent, canActivate: [AuthGuardService, BoardGuardService], children: [
            // {path: '', redirectTo: 'dashboard/default', pathMatch: 'full'},
            { path: '', redirectTo: 'users', pathMatch: 'full' },
            { path: 'dashboard', redirectTo: 'dashboard/default' },
            { path: 'dashboard/:id', pathMatch: 'full', component: DashboardComponent },
            { path: 'users', pathMatch: 'full', component: UsersComponent },
            { path: 'shifts', pathMatch: 'full', component: ShiftsComponent },
            { path: 'shifts/:id/edit', pathMatch: 'full', component: EditShiftComponent },
            { path: 'history', pathMatch: 'full', component: HistoryComponent },
            { path: 'agent-shifts', pathMatch: 'full', component: AgentShiftsComponent },
            { path: 'department-roles', pathMatch: 'full', component: DepartmentRolesComponent },
            { path: 'agent-settings', pathMatch: 'full', component: AgentSettingsComponent },
            { path: 'agent-goals', pathMatch: 'full', component: AgentGoalsComponent },
            { path: 'general-settings', pathMatch: 'full', component: GeneralSettingsComponent },
            { path: 'holidays', pathMatch: 'full', component: HolidaysComponent },
            { path: 'create-view', pathMatch: 'full', component: CreateViewComponent },
            { path: 'edit-view/:id', pathMatch: 'full', component: EditViewComponent },
            { path: 're-assign-pool', pathMatch: 'full', component: ReAssignPoolComponent },
            { path: 'assign-report', pathMatch: 'full', component: AssignReportComponent },
            { path: 'contact-report', pathMatch: 'full', component: ContactReportComponent },
            { path: 'call-report', pathMatch: 'full', component: CallReportComponent },
            { path: 'queue', pathMatch: 'full', component: QueueComponent },
            { path: 'agent-statistics', pathMatch: 'full', component: AgentStatisticsComponent },
            { path: 'access-denied', component: AccessDeniedComponent },
            { path: 'roles', component: RolesComponent },
            { path: 'roles/:id/permissions', component: PermissionsComponent },
            { path: 'agents-kpi', component: AgentsKPIComponent },
            { path: 'ai-settings', component: AssignSettingsComponent },
            { path: 'ai-suggestion', component: AssignSuggestionComponent },
            { path: 'bulk-assign', component: BulkAssignComponent },
            { path: 'bulk-assign/:id', component: BulkAssignIdComponent },
            { path: 'reassign-scripts', loadChildren: './pages/reassign-pull/reassign-pull.module#ReassignPullModule' },
            { path: 'ip-control', loadChildren: './pages/ip-control/ip-control.module#IpControlModule' },
            { path: 'reshuffles', loadChildren: './pages/reshuffles/reshuffles.module#ReshufflesModule' },
            { path: 'reshuffle', loadChildren: './pages/reshuffles/reshuffles.module#ReshufflesModule' },
          
            { path: 'pending-reshuffles', loadChildren: './pages/pending-reshuffles/pending-reshuffles.module#PendingReshufflesModule' },
            { path: 'pending-reshuffle', loadChildren: './pages/pending-reshuffles/pending-reshuffles.module#PendingReshufflesModule' },
            { path: 'auto-assign', loadChildren: './pages/auto-assign/auto-assign.module#AutoAssignModule' },
            { path: 'hold', loadChildren: './pages/hold/hold.module#HoldModule' },
            { path: 'ai-assign', loadChildren: './pages/ai-assign/ai-assign.module#AiAssignModule' },
        ]
    },
    {
        path: '', component: GeneralComponent, canActivate: [AuthGuardService], children: [
            {
                path: 'board',
                loadChildren: './pages/boards/boards.module#BoardsModule'
            },
            { 
                path: 'profile', 
                loadChildren: './pages/profile/profile.module#ProfileModule'
            },
            {
                path: 'general/auto-assign',
                data: {
                    clearBoard: true
                },
                loadChildren: './pages/auto-assign/auto-assign.module#AutoAssignModule'
            }
        ]
    },
    {
        path: '', component: EmptyComponent, children: [
            { path: 'login', pathMatch: 'full', component: LoginComponent },
        ]
    },
    { path: '**', component: NotfoundComponent },
];
