import {Component, OnInit} from '@angular/core';
import * as LoginAction from './actions/login.actions';
import {Store} from '@ngrx/store';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import md5 from 'md5';
import _get from 'lodash/get';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})

export class AppComponent implements OnInit {

    public user = JSON.parse(localStorage.getItem('user'));

    public constructor(
        private store: Store<any>,
        private router: Router
    ) {}

    public ngOnInit() {
        const user = localStorage.getItem('user');
        if (user) {
            this.store.dispatch(new LoginAction.SetLogin(JSON.parse(user)));
        }

        const active_board = JSON.parse(localStorage.getItem('active_board') || null);

        if (active_board) {
            this.store.dispatch(new LoginAction.SetActiveBoard(active_board));
        }

        this.identity();

        this.router.events.pipe(
            filter(e => e instanceof NavigationEnd)
        ).subscribe((navEnd: NavigationEnd) => {
            if (!this.user) {
                this.user = JSON.parse(localStorage.getItem('user'));

                this.identity();
            }

            this.onRouteChange();
        });
    }

    public identity() {
        if (this.user && this.user.id) {
            window['cve']('identity', md5(this.user.id));
        }
    }

    public onRouteChange(): void {
        window['cve']('track', 'assign_system', 'page_visit', {
            id: _get(this.user, 'id', {})
        });
    }


}
